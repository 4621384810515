<template>
  <b-card title="List Item">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="8" />
        <b-col md="4" cols="12">
          <b-form-input
            v-model="search"
            @input="(value) => doFiltering(value)"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :items="items"
            :fields="fields"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    @click="toDetailItem(row)"
                    class="mr-1"
                    variant="info"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lodash from "lodash";

export default {
  data() {
    return {
      fields: [
        {
          key: "itemModel",
          label: "Item Model",
          filterable: true,
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          filterable: true,
          sortable: true,
        },
        // { key: 'price', label: 'Price', filterable: true, filterable: true, sortable: true },
        // { key: 'pointIssued', label: 'Point Issued'},
        { key: "status", label: "Status", filterable: true, sortable: true },
        { key: "actions", label: "Action" },
      ],
      row: 0,
      search: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      showSpinner: false,
      tempMetadata: "",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      getItems: "item/getItems",
    }),
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryItem();
    }, 500),
    queryItem() {
      this.getItems({
        page: this.currentPage,
        entry: this.perPage,
        description: this.search,
      }).then((metadata) => {
        this.processMetadata(metadata.metadata);
      });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.queryItem()
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    toDetailItem(row) {
      this.$router.push(`/item/view/${row.item._id}`);
    },
  },
  computed: {
    ...mapGetters({
      items: "item/items",
    }),
  },
  created() {
    document.title = "Item | RSP";
    this.getItems({
      page: this.currentPage,
      entry: this.perPage,
    }).then((data) => this.processMetadata(data.metadata));
  },
};
</script>
